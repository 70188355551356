.drawings {
    // position: relative;
    position: sticky;
    margin-top: -5.5rem;
    @media (max-width: 767.99px) {
        margin-top: -2.5rem;
    }
    // @media (max-width: 475.99px) {
    //     margin-top: 0;
    // }
    &__main-layer {
        background-image: url("../img/footerbg.png");
        width: 100%;
        height: 37rem;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__cartoons-layer {
        .cartoons {
            position: absolute;
            width: 100%;
            bottom: -11.4%;
        }
    }
    @media (max-width: 1399px) {
        &__main-layer {
            height: 35rem;
        }
    }
    @media (max-width: 1099px) {
        &__main-layer {
            height: 28rem;
        }
    }
    @media (max-width: 991.99px) {
        &__main-layer {
            height: 25rem;
        }
    }
    @media (max-width: 924.99px) {
        &__main-layer {
            height: 24rem;
        }
    }
    @media (max-width: 870.99px) {
        &__main-layer {
            height: 22rem;
        }
    }
    @media (max-width: 768.99px) {
        &__main-layer {
            height: 20rem;
        }
    }
    @media (max-width: 690.99px) {
        &__main-layer {
            height: 18rem;
        }
    }
    @media (max-width: 620.99px) {
        &__main-layer {
            height: 16rem;
        }
    }
    @media (max-width: 550.99px) {
        &__main-layer {
            height: 14rem;
        }
    }
    @media (max-width: 480.99px) {
        &__main-layer {
            height: 12rem;
        }
    }
    @media (max-width: 360.99px) {
        &__main-layer {
            height: 10rem;
        }
    }
    @media (max-width: 330.99px) {
        &__main-layer {
            height: 9rem;
        }
        &__cartoons-layer {
            .cartoons {
                bottom: -9%;
            }
        }
    }
    .books {
        max-width: 100%;
        position: absolute;
        bottom: 14%;
        @media (max-width: 390px) {
            bottom: 14%;
        }
    }
}
