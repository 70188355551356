@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600&family=Poppins:wght@400;500;600&display=swap");
* {
    font-family: "Poppins", "Inter", sans-serif !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 14px;
}

$body-bg: #f3f4ff;
$body-color: #050516;
$main-color: #49bf76;
$button-color: #f1800d;
$heading: #393f42;
$heading-dark: #ffffff;

$theme-colors: (
    "site-white": #ffffff,
    "site-black": #393f42,
);
@import "node_modules/bootstrap/scss/bootstrap";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "./Header.scss";
@import "./BgDrawing.scss";
@import "./FormComponent.scss";
@import "./Form.scss";

.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}
.form-control:focus {
    border-color: none !important;
    box-shadow: none !important;
}
.cursor-pointer {
    cursor: pointer;
}

$weights: 100 200 300 400 500 600 700 800;
@each $weight in $weights {
    .fw--#{$weight} {
        font-weight: $weight;
    }
}
@for $size from 10 through 96 {
    .fs--#{$size} {
        font-size: $size/16 + rem !important;
    }
}

@media (max-width: 991.99px) {
    .container {
        max-width: 100% !important;
    }
}

body {
    // background-color: $body-bg;
    background: #ffffff !important;
    color: $body-color;
    box-sizing: border-box;
    position: relative;
    top: 0px;
}
.modal-dialog {
    min-width: 85% !important;
}
.modal-content {
    padding: 1rem !important;
    ul {
        padding-left: 2rem !important ;
    }
}
.footer {
    position: relative;
    z-index: 1000;
}
.modal-footer {
    button {
        background: $button-color !important;
        border: 0;
        &:hover {
            background-color: $button-color !important;
        }
        &:active {
            background-color: $button-color !important;
        }
    }
}
