.hero {
    position: relative;
    height: 78vh;
    &__form {
        background: #ffffff;
        position: relative;
        width: 65%;
        margin: 0 auto;
        margin-top: 2rem;
        // filter: drop-shadow(0px 6px 25.5px rgba(0, 0, 0, 0.33));
        box-shadow: 0px 6px 25.5px rgba(0, 0, 0, 0.63);
        z-index: 5;
        &-branding {
            padding: 1rem 1.5rem 0 1.5rem;
            &-stars {
                width: 100%;
                // height: 12rem;
            }
        }
        &-banner {
            background: $main-color;
            padding: 1rem 1.5rem;

            &_title {
                text-align: center;
                color: #ffffff;
                font-size: 20px;
                font-weight: 600;

                @media (max-width: 992px) {
                    font-size: 17px;
                }
                @media (max-width: 768px) {
                    font-size: 15px;
                }
                @media (max-width: 650px) {
                    font-size: 13px;
                }
            }
        }
        &-red_heading {
            text-align: center;
            font-size: 13px;
            padding: 0.5rem 0;
            font-weight: 800;
            @media (max-width: 650px) {
                font-size: 9px;
            }
            @media (max-width: 550px) {
                padding: 0 1rem;
            }
            span {
                color: red;
                font-size: 13px;
                @media (max-width: 650px) {
                    font-size: 9px;
                }
            }
        }
        &-question {
            text-align: center;
            padding: 0 8px !important;
            font-size: 20px;
            font-weight: 900;
            color: #343a40;
            @media (max-width: 992px) {
                font-size: 17px;
            }
            @media (max-width: 768px) {
                font-size: 15px;
            }
            @media (max-width: 650px) {
                font-size: 13px;
                // padding-bottom: 4px !important;
            }
            @media (max-width: 475px) {
                font-size: 13px;
            }
            @media (max-width: 425px) {
                font-size: 13px;
                padding: 0 0.5rem !important;
            }
            // @media (max-width: 350px) {
            //     font-size: 12px;
            // }
        }
        &__button-group {
            @media (max-width: 650px) {
                // padding-bottom: 1.5rem !important;
                margin: 0 1rem 0 1rem !important;
                padding: 1rem 0 !important;
            }
        }
        &_button {
            padding: 0.6rem;
            background-color: $button-color;
            border: none;
            border-radius: 5px !important;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 800;
            @media (max-width: 768px) {
                padding: 0.4rem;
            }
            @media (max-width: 550px) {
                margin-right: 1rem !important;
                margin-left: 1rem !important;
                font-size: 11px;
            }
            @media (max-width: 425px) {
                font-size: 10px;
            }
            @media (max-width: 350px) {
                font-size: 9px;
            }
            &:hover {
                background-color: $button-color;
            }
            &:active {
                background-color: $button-color !important;
            }
        }
        &_button_second {
            min-width: 100%;
            padding: 0.6rem;
            background-color: #f1800d;
            border: none;
            border-radius: 5px !important;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 800;
            &:hover {
                background-color: $button-color;
            }
            &:active {
                background-color: $button-color !important;
            }

            @media (max-width: 768px) {
                padding: 0.4rem;
                font-size: 10px;
            }
            @media (max-width: 550px) {
                font-size: 10px;
            }
            @media (max-width: 425px) {
                font-size: 8px;
            }
            @media (max-width: 325px) {
                font-size: 7px;
            }
        }
        &__loader {
            padding: 12rem 0;
            &-text {
                margin-top: 2rem;
                font-size: 25px;
                color: #000000;
                font-weight: 700;
                @media (max-width: 768px) {
                    font-size: 18px;
                }
                @media (max-width: 650px) {
                    font-size: 13px;
                }
            }
        }
        @media (max-width: 768px) {
            &-banner {
                padding: 2rem 0.5rem;
            }
        }
        @media (max-width: 650px) {
            // margin: 4rem auto;
            padding: 1rem 0;
        }
        @media (max-width: 475px) {
            // margin: 4rem auto;
            width: 90%;
            &-banner {
                padding: 1.5rem 0.5rem;
            }
            &__loader {
                padding: 6rem 0;
            }
        }
    }
    @media (max-width: 1399px) {
        height: 73vh;
    }
    @media (max-width: 840px) {
        height: 68vh;
    }
    @media (max-width: 768px) {
        height: 65vh;
    }
    @media (max-width: 699px) {
        height: 58vh;
    }
    @media (max-width: 425px) {
        height: 55vh;
    }
    @media (max-width: 325px) {
        height: 59vh;
    }
}
