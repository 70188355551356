.header {
    background: $main-color;
    &__img {
        width: 2.5rem;
        @media (max-width: 399.99px) {
            margin-right: 0.5rem !important;
        }
        @media (max-width: 355.99px) {
            width: 2rem;
        }
    }
    &__text {
        font-size: 14px;
        color: #ffffff;
        font-style: italic;
        font-family: "Helvetica Neue";
        @media (max-width: 768px) {
            font-size: 12px;
        }
        @media (max-width: 625px) {
            font-size: 10px;
        }
        @media (max-width: 399.99px) {
            font-size: 9px;
        }
        @media (max-width: 355.99px) {
            font-size: 8px;
        }
    }
}
