.hero_form {
    position: relative;
    // height: 78vh;
    .contact__form {
        background: #ffffff;
        position: relative;
        width: 60%;
        margin: 0 auto;
        margin-top: 2rem;
        z-index: 5;
        // filter: drop-shadow(0px 6px 25.5px rgba(0, 0, 0, 0.33));
        box-shadow: 0px 6px 25.5px rgba(0, 0, 0, 0.63);
        // @media (max-width: 476.99px) {
        //     margin: 2rem auto;
        // }
        &_main-form {
            padding: 2rem 1rem;
            @media (max-width: 768px) {
                padding: 1.5rem 1rem;
            }
            .heading {
                color: #297e9a;
                font-size: 20px;
                font-weight: 800;
                text-align: center;
                @media (max-width: 992px) {
                    font-size: 20px;
                }
                @media (max-width: 768px) {
                    font-size: 15px;
                }
                @media (max-width: 385px) {
                    font-size: 13px;
                }
                @media (max-width: 330px) {
                    font-size: 11px;
                }
            }
            .sub_heading {
                font-size: 13px;
                color: #343a40;
                // font-weight: 500;
                font-weight: 400;
                text-align: center;
                line-height: 22px;
                @media (max-width: 992px) {
                    font-size: 14px;
                }
                @media (max-width: 768px) {
                    font-size: 11px;
                    line-height: 14px;
                }
                @media (max-width: 385px) {
                    font-size: 10px;
                    line-height: 11px;
                }
                @media (max-width: 330px) {
                    font-size: 8px;
                }
                span {
                    font-size: 13px;
                    font-weight: 800;
                    @media (max-width: 992px) {
                        font-size: 14px;
                    }
                    @media (max-width: 768px) {
                        font-size: 10px;
                    }
                    @media (max-width: 330px) {
                        font-size: 8px;
                    }
                }
            }
            &_banner {
                .banner_title {
                    background: $main-color;
                    padding: 2rem 6rem;
                    text-align: center;
                    font-size: 20px;
                    color: #ffffff;
                    @media (max-width: 992px) {
                        font-size: 17px;
                        padding: 1rem 6rem;
                    }
                    @media (max-width: 768px) {
                        font-size: 13px;
                        padding: 1rem 3rem;
                    }
                    @media (max-width: 630px) {
                        font-size: 12px;
                        padding: 1rem 2rem;
                    }
                    @media (max-width: 385px) {
                        font-size: 10px;
                        padding: 1rem 8px;
                    }
                    @media (max-width: 330px) {
                        font-size: 10px;
                        padding: 1rem 6px;
                    }
                    span {
                        font-size: 20px;
                        color: #ffffff;
                        text-decoration: underline;
                        @media (max-width: 992px) {
                            font-size: 17px;
                        }
                        @media (max-width: 768px) {
                            font-size: 13px;
                        }
                        @media (max-width: 630px) {
                            font-size: 12px;
                        }
                        @media (max-width: 385px) {
                            font-size: 10px;
                        }
                        // @media (max-width: 330px) {
                        //     font-size: 10px;
                        // }
                    }
                }
            }
        }

        .form__row {
            margin: 1rem 0 !important;
            .col {
                @media (max-width: 430px) {
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                }
            }
        }

        .form-control {
            font-size: 13px;
            padding: 1.3rem 1rem !important;
            @media (max-width: 575px) {
                padding: 0.5rem 1rem !important;
                font-size: 11px;
            }
            @media (max-width: 430px) {
                padding: 0.3rem 0.5rem !important;
                font-size: 8px;
            }
            @media (max-width: 330px) {
                padding: 0.3rem 0.5rem !important;
                font-size: 6px;
            }
        }
        .form-check-input {
            margin-top: 0.2rem !important;
            @media (max-width: 992.99px) {
                margin-top: 0.1rem !important;
            }
        }
        .check_label {
            font-size: 12px;
            color: #343a40;
            font-weight: 500;
            @media (max-width: 992px) {
                font-size: 10px;
            }
            @media (max-width: 630px) {
                font-weight: 400;
                font-size: 6px;
            }
            // @media (max-width: 430px) {
            //     font-size: 6px;
            // }
        }
        .submit_button {
            background: $button-color;
            filter: drop-shadow(5.143px 3.09px 5px rgba(0, 0, 0, 0.33));
            border: 0;
            font-size: 19px;
            font-weight: 800;
            padding: 1rem 5rem;
            border-radius: 10px;
            &:active {
                background-color: $button-color !important;
            }
            &:hover {
                background-color: $button-color !important;
            }
            @media (max-width: 1199px) {
                font-size: 15px;
            }
            @media (max-width: 992px) {
                font-size: 13px;
            }
            @media (max-width: 855px) {
                font-size: 12px;
                padding: 1rem 4rem;
            }
            @media (max-width: 720px) {
                font-size: 10px;
                // padding: 1rem 4rem;
            }
            @media (max-width: 660px) {
                font-size: 8px;
                padding: 0.7rem 3rem;
                // padding: 1rem 4rem;
            }
            // @media (max-width: 605px) {
            //     font-size: 9px;
            // }
            @media (max-width: 530px) {
                // font-size: 8px;
                padding: 0.7rem 2rem;
            }
            @media (max-width: 430px) {
                font-size: 8px;
                padding: 0.6rem 1.5rem;
            }
            @media (max-width: 330px) {
                font-size: 8px;
                padding: 0.7rem 0.5rem;
            }
        }
        .gratis_img {
            max-width: 18%;
            position: absolute;
            top: -4rem;
            left: 91%;
            @media (max-width: 992px) {
                max-width: 17%;
                top: -3rem;
                left: 92%;
            }
            @media (max-width: 768px) {
                max-width: 18%;
                top: -2rem;
                left: 91%;
            }
            @media (max-width: 590px) {
                max-width: 20%;
                // top: -2rem;
                // left: 91%;
            }
        }

        @media (max-width: 475px) {
            width: 90%;
            .gratis_img {
                max-width: 18%;
                left: 90%;
                top: -2.5rem;
            }
            .form-check-input {
                width: 10px;
                height: 10px;
            }
        }
        @media (max-width: 430px) {
            .gratis_img {
                // max-width: 18%;
                // left: 90%;
                top: -2rem;
            }
        }
    }
    // @media (max-width: 1399px) {
    //     height: 73vh;
    // }
    // @media (max-width: 840px) {
    //     height: 68vh;
    // }
    // @media (max-width: 768px) {
    //     height: 65vh;
    // }
    // @media (max-width: 699px) {
    //     height: 58vh;
    // }
    // @media (max-width: 425px) {
    //     height: 55vh;
    // }
    // @media (max-width: 325px) {
    //     height: 62vh;
    // }
}
